<template>
  <div id="query_use">

    <div id="__next">
      <div class="overflow-hidden w-full h-full relative" >
        <div class="flex h-full flex-1 flex-col md:pl-[260px]">

          <main class="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
            <!-- 聊天窗 -->
            <div class="flex-1 overflow-hidden">
              <div class="react-scroll-to-bottom--css-ncqif-79elbk h-full dark:bg-gray-800">
                <div ref="chatContainer" class="react-scroll-to-bottom--css-krija-1n7m0yu">
                  <div class="flex flex-col items-center text-sm dark:bg-gray-800">
                    <!-- 对话item -->
                    <template v-for="conv, idx in conversation">
                      <!-- human -->
                      <div v-if="conv.speaker == 'human'"
                        class="w-full border-b border-black/10 dark:border-gray-900/50 text-gray-800 dark:text-black group dark:bg-gray-800">
                        <div
                          class="text-base gap-4 md:gap-6 m-auto md:max-w-2xl lg:max-w-2xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0">
                          <div class="w-[30px] flex flex-col relative items-end">
                            <div class="relative flex">
                              <span
                                style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;">
                                <span
                                  style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;">
                                  <img aria-hidden="true" :src="require('./assets/imgs/human' + avatarIdx + '.png')"
                                    alt="huamn"
                                    style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;">
                                </span>
                              </span>
                            </div>
                          </div>
                          <div class="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
                            <div class="flex flex-grow flex-col gap-3">
                              <div class="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap">{{
                                conv.speech
                              }}
                              </div>
                            </div>
                            <div v-if="false"
                              class="text-gray-400 flex self-end lg:self-center justify-center mt-2 gap-3 md:gap-4 lg:gap-1 lg:absolute lg:top-0 lg:translate-x-full lg:right-0 lg:mt-0 lg:pl-2 visible">
                              <button
                                class="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                                  stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </button>
                            </div>
                            <div class="flex justify-between"></div>
                          </div>
                        </div>
                      </div>

                      <!-- ai -->
                      <div v-if="conv.speaker == 'ai'"
                        class="w-full border-b border-black/10 dark:border-gray-900/50 text-gray-800 dark:text-gray-100 group bg-gray-50 dark:bg-[#444654]">
                        <div
                          class="text-base gap-4 md:gap-6 m-auto md:max-w-2xl lg:max-w-2xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0">
                          <div class="w-[30px] flex flex-col relative items-end">
                            <div
                              class="relative h-[30px] w-[30px] p-1 rounded-sm text-white flex items-center justify-center"
                              style="background-color: rgb(16, 163, 127);">
                              <svg t="1723427235831" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15367" data-spm-anchor-id="a313x.search_index.0.i12.40213a81U9IgXP" width="41" height="41">
                                <path d="M271.36 107.008l3.982222 3.299556 96.540445 96.597333A334.392889 334.392889 0 0 1 512 176.355556c50.005333 0 97.450667 10.922667 140.117333 30.549333l96.597334-96.597333 3.925333-3.299556a28.444444 28.444444 0 0 1 39.594667 39.594667l-3.299556 3.982222-85.617778 85.617778A335.246222 335.246222 0 0 1 847.644444 512c0 47.331556-9.841778 93.297778-28.501333 135.566222a130.787556 130.787556 0 0 1-42.211555 250.993778l-8.931556 0.284444h-512a130.844444 130.844444 0 0 1-51.086222-251.335111A333.767111 333.767111 0 0 1 176.355556 512a335.246222 335.246222 0 0 1 144.327111-275.797333L235.064889 150.528a28.444444 28.444444 0 0 1 36.295111-43.52zM768 694.044444h-512a73.955556 73.955556 0 0 0-7.566222 147.512889l7.566222 0.398223h512a73.955556 73.955556 0 1 0 0-147.911112zM512 233.244444a278.755556 278.755556 0 0 0-249.173333 403.911112h498.232889l0.568888-0.853334A278.755556 278.755556 0 0 0 512 233.244444zM102.4 381.155556a28.444444 28.444444 0 0 1 27.989333 23.324444l0.455111 5.12v179.2a28.444444 28.444444 0 0 1-56.433777 5.12l-0.455111-5.12V409.6a28.444444 28.444444 0 0 1 28.444444-28.444444z m819.2 0a28.444444 28.444444 0 0 1 27.989333 23.324444l0.455111 5.12v179.2a28.444444 28.444444 0 0 1-56.433777 5.12l-0.455111-5.12V409.6a28.444444 28.444444 0 0 1 28.444444-28.444444z m-512 28.444444a51.2 51.2 0 1 1 0 102.4 51.2 51.2 0 0 1 0-102.4z m204.8 0a51.2 51.2 0 1 1 0 102.4 51.2 51.2 0 0 1 0-102.4z" fill="#ffffff" p-id="15368" data-spm-anchor-id="a313x.search_index.0.i13.40213a81U9IgXP" class="selected">
                                </path></svg>
                            </div>

                            <!-- 多个消息 -->
                            <div v-if="conv.speeches.length > 1"
                              class="text-xs flex items-center justify-center gap-1 invisible absolute left-0 top-2 -ml-4 -translate-x-full group-hover:visible">
                              <button @click.stop="last(conv)" :disabled="!(conv.idx > 0)"
                                class="dark:text-white disabled:text-black dark:disabled:text-gray-400">
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24"
                                  stroke-linecap="round" stroke-linejoin="round" class="h-3 w-3" height="1em" width="1em"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <polyline points="15 18 9 12 15 6"></polyline>
                                </svg>
                              </button>
                              <span class="flex-grow flex-shrink-0">{{ conv.idx + 1 }} / {{
                                conv.speeches.length
                              }}</span>
                              <button @click.stop="next(conv)" :disabled="!(conv.idx < conv.speeches.length - 1)"
                                class="dark:text-white disabled:text-gray-300 dark:disabled:text-gray-400">
                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24"
                                  stroke-linecap="round" stroke-linejoin="round" class="h-3 w-3" height="1em" width="1em"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div class="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
                            <div class="flex flex-grow flex-col gap-3">
                              <!--  whitespace-pre-wrap -->
                              <div class="min-h-[20px] flex flex-col items-start gap-4">
                                <div v-html="mdToHtml(conv.speeches[conv.idx], conv)"
                                  :class="{ 'result-streaming': conv.loading }"
                                  class="markdown prose-r w-full break-words dark:prose-invert light">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <div v-if="conversation.length == 0"
                      class="text-gray-800 w-full md:max-w-2xl lg:max-w-3xl md:h-full md:flex md:flex-col px-6 dark:text-gray-100">
                      <h1
                        class="text-4xl font-semibold text-center mt-6 sm:mt-[20vh] ml-auto mr-auto mb-10 sm:mb-16 flex gap-2 items-center justify-center">
                        汇通金财-智能伙伴</h1>
                    </div>

                    <div class="w-full h-32 md:h-48 flex-shrink-0"></div>
                  </div>

                  <transition name="el-fade-in-linear">
                    <!-- 回到底部 -->
                    <button v-show="isShowGoBottom" @click="handleScrollBottom"
                      class="cursor-pointer absolute right-6 bottom-[124px] md:bottom-[120px] z-10 rounded-full border border-gray-200 bg-gray-50 text-gray-600 dark:border-white/10 dark:bg-white/10 dark:text-gray-200">
                      <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                        stroke-linejoin="round" class="h-4 w-4 m-1" height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <polyline points="19 12 12 19 5 12"></polyline>
                      </svg>
                    </button>
                  </transition>
                </div>
              </div>
            </div>

            <!-- 底部输入 -->
            <div
              class="absolute bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient bg-white dark:bg-gray-800 md:!bg-transparent dark:md:bg-vert-dark-gradient">
              <form class="stretch mx-2 flex flex-row gap-3 pt-2 last:mb-2 md:last:mb-6 lg:mx-auto lg:max-w-3xl lg:pt-6">
                <div class="relative flex h-full flex-1 md:flex-col">
                  <div class="flex ml-1 md:w-full md:m-auto md:mb-2 gap-0 md:gap-2 justify-center">

                    <button v-if="convLoading" @click.stop.prevent="stopChat" id="stopChat"
                      class="btn relative btn-neutral border-0 md:border">
                      <div class="flex w-full items-center justify-center gap-2">
                        <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24"
                          stroke-linecap="round" stroke-linejoin="round" class="h-3 w-3" height="1em" width="1em"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                        </svg>停止生成
                      </div>
                    </button>

                  </div>
                  <div
                    class="flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]">
                    <textarea v-model="chatMsg" ref="inputChat" @keydown="judgeInput" tabindex="0" data-id="root"
                      style="max-height: 200px; height: 24px; overflow-y: hidden;" rows="1"
                      class="m-0 w-full resize-none border-0 bg-transparent p-0 pl-2 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent md:pl-0"></textarea>
                    <button @click.stop.prevent="send" :disabled="convLoading"
                      class="absolute p-1 rounded-md text-gray-500 bottom-1.5 right-1 md:bottom-2.5 md:right-2 hover:bg-gray-100 dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent">
                      <div v-if="convLoading" class="text-2xl" style="line-height: 1.3rem;">
                        <span class="load_dot1">·</span><span class="load_dot2">·</span><span class="load_dot3">·</span>
                      </div>
                      <svg v-else stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                        stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4 mr-1" height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <line x1="22" y1="2" x2="11" y2="13"></line>
                        <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
              <div class="px-3 pt-2 pb-3 text-center text-xs text-black/50 dark:text-white/50 md:px-4 md:pt-3 md:pb-6">
                <a href="https://gitee.com/MIEAPP/chatai-vue" target="_blank" rel="noreferrer"
                  class="underline"></a> 汇通金财大语言模型是由汇通RPA团队开发，仅供内部使用。
              </div>
            </div>
          </main>
        </div>

        <!-- 菜单导航 -->
        <div class="dark hidden bg-white md:fixed md:inset-y-0 md:flex md:w-[260px] md:flex-col" style="top: 120px ">
          <div class="flex h-full min-h-0 flex-col ">
            <div ref="menu" class="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">

              <nav ref="navEle" class="flex h-full flex-1 flex-col space-y-1 p-2">
                <a @click.stop="newChat"
                  class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-black cursor-pointer text-sm mb-2 flex-shrink-0 border border-white/20">
                  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                    stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                  新建对话
                </a>

                <!-- 对话列表 -->
              <div class="flex-col flex-1 overflow-y-auto border-b border-white/20" style="padding-bottom: 5px;">
                <div class="flex flex-col gap-2 text-black text-sm bg-white">

                  <template v-for="conversation, cidx in conversations">

                    <div v-if="conversation.editable"
                      class="m-focus flex py-3 px-3 items-center gap-3 relative rounded-md cursor-pointer hover:pr-14 break-all pr-14 bg-white hover:bg-white">
                      <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                        stroke-linejoin="round" class="h-4 w-4 flex-shrink-0" height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                      </svg>
                      <input id="titleInput" v-model="convTitletmp" @blur="titleInputBlur(cidx, conversation)"
                        type="text" class="text-sm border-none bg-transparent p-0 m-0 w-full mr-0" autofocus="true">
                      <div class="absolute flex right-1 z-10 text-black visible">
                        <button @click="cancelChangeConvTitle(cidx, conversation)" class="p-1 hover:text-black">
                          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                            stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
                            xmlns="http://www.w3.org/2000/svg">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>

                    <a v-else-if="conversation.delete" @blur="cancelDelConv(cidx, conversation)"
                      class="m-focus flex py-3 px-3 items-center gap-3 relative rounded-md cursor-pointer break-all pr-14 bg-white hover:bg-white group">
                      <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                        stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                      <div class="flex-1 text-ellipsis max-h-5 overflow-hidden break-all relative text-black">Delete "{{
                        conversation.title
                      }}"?
                        <div class="absolute inset-y-0 right-0 w-8 z-10 bg-gradient-to-l from-white"></div>
                      </div>
                      <div class="absolute flex right-1 z-10 text-black visible">
                        <button @click="delConv(cidx)" class="p-1 hover:text-black">
                          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                            stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
                            xmlns="http://www.w3.org/2000/svg">
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                        </button>
                        <button @click="cancelDelConv(cidx, conversation)" class="p-1 hover:text-black">
                          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                            stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
                            xmlns="http://www.w3.org/2000/svg">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </a>

                    <a v-else @click.stop.prevent="selectConversation(conversation, true)"
                      :class="{ 'bg-white hover:bg-white pr-14': conversation.selected, 'hover:bg-gray-100 hover:pr-4': !conversation.selected }"
                      class="flex py-3 px-3 items-center gap-3 relative rounded-md cursor-pointer break-all group">
                      <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                        stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                        </path>
                      </svg>
                      <div class="flex-1 text-ellipsis max-h-5 overflow-hidden break-all relative text-black">
                        {{ conversation.title }}
                        <div
                          :class="{ 'from-white': conversation.selected, 'from-gray-100 group-hover:from-[#e0e0e0]': !conversation.selected }"
                          class="absolute inset-y-0 right-0 w-8 z-10 bg-gradient-to-l">
                        </div>
                      </div>
                    </a>

                  </template>

                </div>
              </div>

                <a v-if="conversations.length > 0" @click.stop.prevent="clearConversations"
                  class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-black cursor-pointer text-sm">
                  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                    stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                    </path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                  清除所有对话
                </a>

              </nav>

            </div>
          </div>
        </div>
      </div>
      <div class="absolute top-0 left-0 right-0 z-[2]"></div>
    </div>

    <div v-show="showSlide" class="semi-portal" style="z-index: 1000;">
      <div class="">
        <div class="semi-modal-mask"></div>
        <div role="none" class="semi-modal-wrap">
          <div class="semi-modal semi-modal-small" id="dialog-3" style="width: 0px;">
            <div role="dialog" aria-modal="true" aria-labelledby="semi-modal-title" aria-describedby="semi-modal-body"
              class="semi-modal-content">
              <div class="semi-modal-body-wrapper">
                <div class="semi-modal-body" x-semi-prop="children">
                  <div class="fixed inset-0 z-40 flex">
                    <div class="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 translate-x-0"
                      id="headlessui-dialog-panel-:r1:" data-headlessui-state="open">
                      <div class="absolute top-0 right-0 -mr-12 pt-2 opacity-100">
                        <button @click="closeShowSlide" type="button"
                          class="ml-1 flex h-10 w-10 items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"><span
                            class="sr-only">Close sidebar</span>
                          <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24"
                            stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6 text-black" height="1em"
                            width="1em" xmlns="http://www.w3.org/2000/svg">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                      <div ref="slideNavContainer" style="width:320px"
                        class="flex h-full flex-1 items-start border-white/20">


                      </div>
                    </div>
                    <div @click="closeShowSlide" style="width:calc(100% - 320px)" class="flex-shrink-0"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div portal-container="">
      <span
        class="pointer-events-none fixed inset-0 z-[60] mx-auto my-2 flex max-w-[560px] flex-col items-stretch justify-start md:pb-5">
      </span>
    </div>

  </div>
</template>


<script>

import { marked } from 'marked';
import hljs from "highlight.js";
import 'highlight.js/styles/github.css';
import './assets/index.css'
import axios from 'axios';

const renderer = {
  code(code, infostring, escaped) {
    var codeHtml = code
    if(infostring && infostring == "html"){
       codeHtml = encodeURIComponent(code);
    }
    if (infostring) {
      codeHtml = hljs.highlightAuto(code).value
    }

    console.log(code, infostring, escaped, codeHtml);

    return `<div class="bg-black mb-4 rounded-md">
      <div class="code_header flex items-center relative text-gray-200 bg-gray-800 px-4 py-2 text-xs font-sans">
        <span>${infostring || ""}</span>
        <button onclick="copy(this)" class="flex ml-auto gap-2">
          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
          </svg>
          <span>Copy code</span>
          <code style="display:none">${encodeURIComponent(code)}</code>
        </button>
      </div>
      <div class="p-4 overflow-y-auto">
        <code class="!whitespace-pre hljs language-${infostring}">${codeHtml}</code>
      </div>
    </div>`;
  },
  paragraph(text) {
    return `<p style="white-space:pre-wrap;">${text}</p>`
  }
};
marked.use({ renderer });

export default {
  data() {
    return {
      theme: "light",
      avatarIdx: 1,
      conversations: [],
      conversation: [],
      chatMsg: "",
      chatTitle: "汇通金财助手",
      convLoading: false,
      showSlide: false,
      isShowGoBottom: false,
      oldConv: undefined,
      convTitletmp: "",
      source: undefined,
      rsource: undefined,
      tsource: undefined
    };
  },
  methods: {
    closeSource() {
      var that = this;
      if (that.source) {
        that.source.close();
        that.source = undefined;
      }
      if (that.tsource) {
        that.tsource.close();
        that.tsource = undefined;

      }
      if (that.rsource) {
        that.rsource.close();
        that.rsource = undefined;
      }
    },

    stopChat() {
      var that = this;

      var rconv = that.conversation[that.conversation.length - 1];
      rconv["loading"] = false;
      that.convLoading = false;

      if (that.conversation.length == 2 && rconv["speeches"].length == 1) {
        var newConv = {
          "id": that.cid,
          "title": "新聊天"
        };

        that.generateConvTitle(newConv);
        that.conversations.unshift(newConv);
        that.selectConversation(newConv, false);
        that.saveConversations();
      }

      that.refrechConversation();
      that.closeSource();
    },

    closeShowSlide() {
      this.showSlide = false;
      this.$refs.menu.appendChild(this.$refs.navEle);
    },
    showSlideMethod() {
      this.showSlide = true;
      this.$refs.slideNavContainer.appendChild(this.$refs.navEle);
    },
    changeHeight() {
      var elem = this.$refs.inputChat;
      elem.style.height = '24px';
      var scrollHeight = elem.scrollHeight;
      if (24 >= scrollHeight || this.chatMsg.length == 0) {
        this.resetHeight();
        return;
      }

      elem.style.removeProperty("overflow-y")
      elem.style.height = scrollHeight + 'px';
    },
    resetHeight() {
      var elem = this.$refs.inputChat;
      elem.style.height = '24px';
      elem.style["overflow-y"] = 'hidden';
    },
    vueCopy(node) {
      var code = node.getElementsByTagName("code")[0].innerHTML
      var text = decodeURIComponent(code);
      this.$copyText(text).then(
        res => {
          var svg = `<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span>Copied!</span>`
          const nodeInnerHtml = node.innerHTML
          node.innerHTML = svg;

          setTimeout(() => {
            node.innerHTML = nodeInnerHtml
          }, 1000);
        },
        err => {
          console.log('复制失败');
        }
      );
    },
    initConvs(convs) {
     // 检查如果 convs 是字符串，则尝试解析它为数组
      if (typeof convs === 'string') {
        try {
          convs = JSON.parse(convs);
          // console.log('Parsed convs to array:', convs);
        } catch (error) {
          // console.error('Failed to parse convs as JSON:', error);
          return []; // 如果解析失败，返回空数组或根据需求处理
        }
      }

      // 检查解析后的 convs 是否是数组
      if (!Array.isArray(convs)) {
        // console.error('convs is not an array after parsing:', convs);
        return []; // 返回空数组或根据需求处理
      }

      // console.log('convs array length:', convs.length);

      for (let i = 0; i < convs.length; i++) {
        const conv = convs[i];
        // console.log('Processing conv:', conv);

        if (conv.speaker === "human") {
          continue;
        }

        if (!Array.isArray(conv["speeches"])) {
          conv["speeches"] = [];
          // console.warn(`conv["speeches"] is undefined, initializing to empty array.`);
        }

        conv["idx"] = conv["speeches"].length > 0 ? conv["speeches"].length - 1 : 0;
      }

      return convs;
    },

    last(conv) {
      if (conv.idx == 0) {
        return;
      }
      conv.idx--;
      this.refrechConversation();
    },
    suitable(idx, conv, suit) {
      var that = this;
      var cdate = {
        "idx": idx,
        "msg_idx": conv.idx,
        "suitable": suit,
      }
      conv.suitable[conv.idx] = suit

      this.axios.put(`/api/ai/suitable/${this.cid}`, cdate)
        .then((result) => {
          console.log(result);

          that.refrechConversation()
        })
        .catch((err) => {
        });
    },
    next(conv) {
      if (conv.idx == conv["speeches"].length - 1) {
        return;
      }
      conv.idx++;
      this.refrechConversation();
    },
    inputChat(msg) {
      console.log("输入",msg);
      this.chatMsg = msg;
    },
    countAndConcat(str, substr) {
      // 使用正则表达式的全局匹配来查找子字符串
      const matches = str.match(new RegExp(substr, 'g'));

      // 判断子字符串的个数是奇数还是偶数
      const count = matches ? matches.length : 0;
      const isOdd = count % 2 === 1;

      // 根据判断结果返回相应的字符串
      return isOdd ? str + "\n" + substr : str;
    },
    mdToHtml(md, conv) {
      if (md == "") {
        return "<p></p>"
      }

      md = this.countAndConcat(md, "```")

      var htmlMD = marked.parse(md);
      htmlMD = htmlMD.trim();
      return htmlMD;
    },
    // refrechConversation() {
    //   this.conversation = JSON.parse(JSON.stringify(this.conversation));
    // },
    refrechConversation() {
      console.log('Refreshing conversation:', this.conversation);
      this.conversation = [...this.conversation];
      this.$nextTick(() => {
        this.handleScrollBottom();
      });
    },

    judgeInput(e) {
      if (!e.shiftKey && e.keyCode == 13) {
        e.cancelBubble = true;  //ie阻止冒泡行为
        e.stopPropagation(); //Firefox阻止冒泡行为
        e.preventDefault(); //取消事件的默认动作*换行
        this.send();
      }
    },
    async send() {
  if (this.chatMsg.trim().length == 0 || this.convLoading) {
    return;
  }

  this.convLoading = true;
  const chatMsg = this.chatMsg.trim().replace(/\n/g, "");
  this.chatMsg = "";

  const first = this.conversation.length == 0;

  this.conversation.push({
    speaker: "human",
    speech: chatMsg
  });

  const conv = {
    idx: 0,
    loading: true,
    speaker: "ai",
    suitable: [0],
    speeches: [""]
  };
  this.conversation.push(conv);

  // 滚动到最下面
  this.handleScrollBottom();

  const that = this;

  try {
    const response = await fetch(`/api/chat/${that.cid}?prompt=${encodeURIComponent(chatMsg)}`);
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let firstChunkReceived = false;

    async function read() {
      let doneReading = false;

      while (!doneReading) {
        const { done, value } = await reader.read();
        doneReading = done;

        let chunk = decoder.decode(value, { stream: true });
        console.log('Received chunk:', chunk);

        let content = chunk;

        // 去除 [DONE] 标记
        if (content.includes("[DONE]")) {
          content = content.replace("[DONE]", ""); // 去除 [DONE] 标记
          conv.loading = false;
          that.convLoading = false;
        }

        console.log('content after [DONE] removal:', content);

        if (done) {
          console.log('Done reading, final conversation:', that.conversation);
          that.refrechConversation();
          return;
        }

        // 如果是首次接收到数据块，生成标题
        if (first && !firstChunkReceived) {
          const newConv = {
            id: that.cid,
            title: ""
          };

          that.generateConvTitle(newConv);
          that.conversations.unshift(newConv);
          that.selectConversation(newConv, false);
          that.saveConversations();
          firstChunkReceived = true;  // 标记已生成标题
        }

        // 替换 [ENTRY] 为换行符
        if (content.includes("[ENTRY]")) {
          content = content.replaceAll("[ENTRY]", "\n");
        }

        // 更新内容并刷新界面
        conv.speeches[0] += content;
        console.log('conv["speeches"][0]:', conv.speeches[0]);

        that.refrechConversation();
        that.handleScrollBottom();
      }
    }

    // 开始读取数据流
    await read();

  } catch (error) {
    console.error('Error fetching chunked response:', error);
    that.convLoading = false;
  }
},


    async send1() {
        if (this.chatMsg.trim().length == 0 || this.convLoading) {
          return;
        }

        this.convLoading = true;
        const chatMsg = this.chatMsg.trim().replace(/\n/g, "");
        this.chatMsg = "";

        const first = this.conversation.length == 0;

        this.conversation.push({
          speaker: "human",
          speech: chatMsg
        });

        const conv = {
          idx: 0,
          loading: true,
          speaker: "ai",
          suitable: [0],
          speeches: [""]
        };
        this.conversation.push(conv);

        // 滚动到最下面
        this.handleScrollBottom();

        const that = this;

        try {
          const response = await fetch(`/api/chat/${that.cid}?prompt=${encodeURIComponent(chatMsg)}`);
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let firstChunkReceived = false;

          async function read() {
            let doneReading = false;

            while (!doneReading) {
              const { done, value } = await reader.read();
              doneReading = done;

              if (done) {
                console.log('Done reading, final conversation:', this.conversation);
                conv.loading = false;
                that.convLoading = false;
                that.refrechConversation();
                return;
              }

              let chunk = decoder.decode(value, { stream: true });
              console.log('Received chunk:', chunk);

              let content = chunk;

              console.log('content:', content);

              if (content.includes("[ENTRY]")) {
                content = content.replaceAll("[ENTRY]", "\n");
              }

              // 更新内容并刷新界面
              conv.speeches[0] += content;
              console.log('conv["speeches"][0]:', conv.speeches[0]);
              
              // 如果是首次接收到数据块，生成标题
              if (first && !firstChunkReceived) {
                const newConv = {
                  id: that.cid,
                  title: ""
                };

              
                that.generateConvTitle(newConv);
                that.conversations.unshift(newConv);
                that.selectConversation(newConv, false);
                that.saveConversations();
                firstChunkReceived = true;  // 标记已生成标题
              }

              if (content.includes("[DONE]")) {
                content = content.replace("[DONE]", ""); // 去除 [DONE] 标记
                conv.loading = false;
                that.convLoading = false;
                that.refrechConversation();
                return;
              }
              
              that.refrechConversation();
              that.handleScrollBottom();
            }
          }

          // 开始读取数据流
          await read();

        } catch (error) {
          console.error('Error fetching chunked response:', error);
          that.convLoading = false;
        }
      },

   generateConvTitle(conv) {
      var that = this;
      // conv.title = "";

      // 发起请求
      axios.get(`/api/conversation/gen_title/${this.cid}`, {
        responseType: 'json',
      })
      .then(response => {
        // 处理响应数据
        const responseData = response.data.data;

        if (responseData === "[DONE]") {
          that.selectConversation(conv, false);
          that.saveConversations();
        } else {
          conv.title += responseData;
          that.selectConversation(conv, false);
        }
      })
      .catch(error => {
        console.log("error:" + error.message);
      });
    },
    newChat() {
      if (this.conversation.length == 0) {
        return
      }

      this.chatTitle = "新聊天";
      document.title = "新聊天";
      var conversations = this.conversations;
      for (let idx in conversations) {
        var conv = conversations[idx];
        delete conv.editable;
        delete conv.selected;
        delete conv.delete;
      }

      this.loadId()
      this.saveConversations()
    },
    loadId() {
      var that = this;
      this.axios.post(`/api/generate/id`, {})
        .then((result) => {
          // console.log('uuid',result);
          var resp = result.data;

          that.cid = resp.data;
          this.conversation = []
        })
        .catch((err) => {
        });
    },
    loadConversations() {
      let convs = localStorage.getItem("conversations") || "[]";
      this.conversations = JSON.parse(convs);
    },
    saveConversations() {
      var conversations = JSON.parse(JSON.stringify(this.conversations));
      for (let idx in conversations) {
        var conv = conversations[idx];
        delete conv.editable;
        delete conv.selected;
        delete conv.delete;
      }
      let convs = JSON.stringify(conversations);
      localStorage.setItem("conversations", convs);
    },
    clearConversations() {
      this.conversations = []
      this.saveConversations();
    },
    selectConversation(conv, loadConv) {
      var that = this;
      if (this.oldConv) {
        this.oldConv.selected = false;
      }
      conv.selected = true
      this.oldConv = conv;

      document.title = conv.title || "汇通金财-智能伙伴";
      this.chatTitle = conv.title || "汇通金财-智能伙伴";

      if (!loadConv) {
        return;
      }

      const conv_id = conv.id
      this.axios.post(`/api/conversation/${conv_id}`, { conv_id })
        .then((result) => {
          // console.log("我选你了",result);
          var resp = result.data;
          // console.log("resp:", resp);
          var content = resp.data;

          // console.log("Parsed Content:", content);
          that.cid = conv.id;
          that.conversation = that.initConvs(content)
          // console.log("Conversation after Init:", that.conversation);
           // 强制刷新视图
          that.refrechConversation();

          setTimeout(() => {
            that.isScrollAndNotBottom();
          }, 300)
        })
        .catch((err) => {
        });
    },
    titleInputBlur(idx, conv) {
      setTimeout(() => {
        this.cancelChangeConvTitle(idx, conv);
      }, 100);
    },
    cancelChangeConvTitle(idx, conv) {
      conv.editable = false;
      this.$set(this.conversations, idx, conv);
    },
    delConv(cidx) {
      this.conversations.splice(cidx, 1);
      this.saveConversations();
    },
    cancelDelConv(idx, conv) {
      conv.delete = false;
      this.$set(this.conversations, idx, conv);
    },
    loadAvatar() {
      let avatar = localStorage.getItem("avatar") || Math.ceil(Math.random() * 9);
      this.avatarIdx = avatar;
    },
    handleScrollBottom() {
      this.$nextTick(() => {
        let scrollElem = this.$refs.chatContainer;
        scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
      });
    },
    isScrollAndNotBottom() {
      let chatDivEle = this.$refs.chatContainer;
      if (!chatDivEle) {
        return;
      }

      if (chatDivEle.scrollHeight <= chatDivEle.clientHeight) {
        this.isShowGoBottom = false;
        return;
      }

      const scrollTop = chatDivEle.scrollTop;
      const windowHeight = chatDivEle.clientHeight;
      const scrollHeight = chatDivEle.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight - 50) {
        this.isShowGoBottom = false;
        return;
      }

      this.isShowGoBottom = true;
    },
  },
  computed: {},
  watch: {
    chatMsg(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.changeHeight();
    }
  },
  // 在路由离开时保存对话
  beforeRouteLeave(to, from, next) {
    this.saveConversations();
    next();
  },
  created() {
    window.addEventListener('beforeunload', this.saveConversations);
  },
  beforeDestroy() {
    
    window.location.reload();

    window.removeEventListener('beforeunload', this.saveConversations);
  },
  mounted: function () {

    var theme = localStorage.getItem("theme") || "light"
    this.loadId();
    this.loadConversations();
    this.loadAvatar();

    let chatDivEle = this.$refs.chatContainer;
    chatDivEle.addEventListener('scroll', this.isScrollAndNotBottom, true)

    window.copy = this.vueCopy
  }
};
</script>

<style scoped>
</style>